body {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInput-underline:after {
  border-bottom: 1px solid #f9b233 !important;
}

span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  border-left: 1px solid #e7e7ed;
  height: 100%;
  line-height: 2.2;
  padding-left: 14px;
  color: #272833 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #f9b233 !important;
}

.MuiRadio-root {
  color: #cdced9 !important;
}

.screen {
  display: grid;
  height: 100vh;
  grid-template-rows: 1fr 100px;
  grid-template-columns: 1fr 4fr;
  grid-template-areas:
    "sidebar-image content"
    "sidebar-image bottom-bar";
}

.sidebar-image {
  grid-area: sidebar-image;
}

.sidebar-image {
  height: 100%;
  width: 100%;
  background-image: url("./assets/image-back.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.sidebar-image img {
  height: 15%;
  width: 15%;
  position: absolute;
  left: 4.5%;
  right: 82.89%;
  top: 5.42%;
  bottom: 87.22%;
}

@media (min-width: 1190px) {
  .sidebar-image img {
    height: 11%;
    width: 11%;
  }
}

.content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 7.8vw;
  margin-bottom: 20px;
}

.bottom-bar {
  grid-area: bottom-bar;
}

@media (max-width: 962px) {
  .screen {
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr 80px;
    grid-template-columns: 320px 1fr;
    grid-template-areas:
      "content content"
      "bottom-bar bottom-bar";
  }
}
